import axios from "axios";
import { API_BASE_URL } from "./config";
import { request } from "./AxiosConfig";

axios.defaults.baseURL = API_BASE_URL;
axios.defaults.headers.common["Accept"] = "application/json";

const getWithoutAuth = async (url, params = {}) => {
  const header = {
    "Content-Type": "application/json",
    data: "sample",
    PLATFORM: "WEB",
  };
  const config = {
    url,
    method: "get", // default
    headers: header,
    params: params,
    timeout: 30000, // default is `0` (no timeout)// 30 sec
    maxRedirects: 2, // default
  };
  return request(config);
};

const postWithoutAuth = async (url, body) => {
  const headers = {
    "Content-Type": "application/json",
    PLATFORM: "WEB",
  };
  const config = {
    url,
    method: "post",
    headers: headers,
    data: body,
    timeout: 30000,
    maxRedirects: 2,
  };
  return request(config);
};

const get = async (url, params = {}) => {
  const token = await localStorage.getItem("token");
  const header = {
    "Content-Type": "application/json",
    data: "sample",
    Authorization: `Bearer ${token}`,
    PLATFORM: "WEB",
  };
  const config = {
    url,
    method: "get", // default
    headers: header,
    params: params,
    timeout: 30000, // default is `0` (no timeout)// 30 sec
    maxRedirects: 2, // default
  };
  return request(config);
};

const post = async (url, body, header) => {
  const token = await localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    PLATFORM: "WEB",
  };
  const config = {
    url,
    method: "post",
    headers: header ?? headers,
    data: body,
    timeout: 30000,
    maxRedirects: 2,
  };
  return request(config);
};

const delWithoutAuth = async (url, params = {}) => {
  const headers = {
    "Content-Type": "application/json",
    PLATFORM: "WEB",
  };
  const config = {
    url,
    method: "delete",
    headers: headers,
    timeout: 30000,
    maxRedirects: 2,
  };
  return request(config);
};

const putWithoutAuth = async (url, body) => {
  const headers = {
    "Content-Type": "application/json",
    PLATFORM: "WEB",
  };
  const config = {
    url,
    method: "put",
    headers: headers,
    data: body,
    timeout: 30000,
    maxRedirects: 2,
  };
  return request(config);
};

const del = async (url, params = {}) => {
  const token = await localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    PLATFORM: "WEB",
  };
  const config = {
    url,
    method: "delete",
    headers: headers,
    timeout: 30000,
    maxRedirects: 2,
  };
  return request(config);
};

const put = async (url, body) => {
  const token = await localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    PLATFORM: "WEB",
  };
  const config = {
    url,
    method: "put",
    headers: headers,
    data: body,
    timeout: 30000,
    maxRedirects: 2,
  };
  return request(config);
};
const patch = async (url, body) => {
  const token = await localStorage.getItem("token");
  const config = {
    url,
    method: "patch",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      PLATFORM: "WEB",
    },
    data: body,
    timeout: 30000,
    maxRedirects: 2,
  };
  return request(config);
};

const filePost = async (url, body) => {
  const token = await localStorage.getItem("token");
  const config = {
    url,
    baseURL: API_BASE_URL,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      PLATFORM: "WEB",
    },
    data: body,
    timeout: 30000,
    withCredentials: false,
    responseEncoding: "utf8",
    maxRedirects: 2,
  };
  return request(config);
};

const NetworkClient = {
  getWithoutAuth,
  postWithoutAuth,
  delWithoutAuth,
  putWithoutAuth,
  get,
  post,
  del,
  put,
  patch,
  filePost,
};

export default NetworkClient;