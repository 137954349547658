export const constant = {
  //user auth
  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",

  //get questionnaire
  GET_QUESTIONNAIRE: "GET_QUESTIONNAIRE",
  GET_QUESTIONNAIRE_SUCCESS: "GET_QUESTIONNAIRE_SUCCESS",
  GET_QUESTIONNAIRE_FAILED: "GET_QUESTIONNAIRE_FAILED",
  CLEAN_QUESTIONNAIRE: "CLEAN_QUESTIONNAIRE",

  //get therapy index
  GET_THERAPY_INDEX: "GET_THERAPY_INDEX",
  GET_THERAPY_INDEX_SUCCESS: "GET_THERAPY_INDEX_SUCCESS",
  GET_THERAPY_INDEX_FAILED: "GET_THERAPY_INDEX_FAILED",
  CLEAN_THERAPY_INDEX: "CLEAN_THERAPY_INDEX",

  //get pre appointment data
  GET_PRE_APPOINTMENT_DATA: "GET_PRE_APPOINTMENT_DATA",
  GET_PRE_APPOINTMENT_DATA_SUCCESS: "GET_PRE_APPOINTMENT_DATA_SUCCESS",
  GET_PRE_APPOINTMENT_DATA_FAILED: "GET_PRE_APPOINTMENT_DATA_FAILED",
  CLEAN_PRE_APPOINTMENT_DATA: "CLEAN_PRE_APPOINTMENT_DATA",

  //get instructor availability
  GET_INSTRUCTOR_AVAILABLITY: "GET_INSTRUCTOR_AVAILABLITY",
  GET_INSTRUCTOR_AVAILABLITY_SUCCESS: "GET_INSTRUCTOR_AVAILABLITY_SUCCESS",
  GET_INSTRUCTOR_AVAILABLITY_FAILED: "GET_INSTRUCTOR_AVAILABLITY_FAILED",
  CLEAN_INSTRUCTOR_AVAILABLITY: "CLEAN_INSTRUCTOR_AVAILABLITY",

  //confirm booking
  CONFIRM_BOOKING: "CONFIRM_BOOKING",
  CONFIRM_BOOKING_SUCCESS: "CONFIRM_BOOKING_SUCCESS",
  CONFIRM_BOOKING_FAILED: "CONFIRM_BOOKING_FAILED",
  CLEAN_CONFIRM_BOOKING: "CLEAN_CONFIRM_BOOKING",

  SET_CONFIRM_BOOKING_DATA: "SET_CONFIRM_BOOKING_DATA",
  CLEAN_CONFIRM_BOOKING_DATA: "CLEAN_CONFIRM_BOOKING_DATA",

  NEW_SESSION_PRE_CLEANUP: "NEW_SESSION_PRE_CLEANUP",

  SIDER_CONDITION: "SIDER_CONDITION",
  FIREBASE_CREDENTIALS: "FIREBASE_CREDENTIALS",
  USER_INFO: "USER_INFO",
  USER_PROFILE_INFO: "USER_PROFILE_INFO",
  SET_JWT: "SET_JWT",
  SET_SETTINGS_ROUTE: "SET_SETTINGS_ROUTE",

  //intake form
  GET_CONSENT_FORM_STATUS: "GET_CONSENT_FORM_STATUS",
  GET_CONSENT_FORM_STATUS_SUCCESS: "GET_CONSENT_FORM_STATUS_SUCCESS",
  GET_CONSENT_FORM_STATUS_FAILED: "GET_CONSENT_FORM_STATUS_FAILED",

  SET_LOGIN_LOADER: "SET_LOGIN_LOADER",
  REDUCE_RATE_OPEN: "REDUCE_RATE_OPEN",

  SELECT_DATE_TIME_OPEN: "SELECT_DATE_TIME_OPEN",
};
