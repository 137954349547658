import { combineReducers } from "redux";
import { constant } from "../constant";
// import { constant } from "../constant";
//Import all reducers in here
import AuthReducer from "./AuthReducer";
import ConfirmBookingDataReducer from "./ConfirmBookingDataReducer";
import ConfirmBookingReducer from "./ConfirmBookingReducer";
import InstructorAvailabilityReducer from "./InstructorAvailabilityReducer";
import PreAppointmentDataReducer from "./PreAppointmentDataReducer";
import QuestionnaireReducer from "./QuestionnaireReducer";
import TherapyReducer from "./TherapyReducer";
import IntakeFormReducer from "./IntakeFormReducer";

const appReducer = combineReducers({
  AuthReducer,
  QuestionnaireReducer,
  PreAppointmentDataReducer,
  ConfirmBookingDataReducer,
  InstructorAvailabilityReducer,
  ConfirmBookingReducer,
  TherapyReducer,
  IntakeFormReducer,
});

const reducers = (state, action) => {
  if (action.type === constant.LOGOUT_SUCCESS) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default reducers;
