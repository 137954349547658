import { Button, Col, ConfigProvider, Menu, Row } from "antd";
import React, { useCallback, useState } from "react";
import { FaCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { bindActionCreators } from "redux";
import headerLogo from "../assets/headerLogo.png";
import { ActionCreators, persistor } from "../store";
import { setToken } from "../store/api/config/AxiosConfig";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import googleTagFire from "../utills/googleTagFire";
import { signOut } from "firebase/auth";
import "./customHeader.css";
import { auth } from "../utills/firebase-config";
import { showMessege } from "./CustomMessage";
import { useEffect } from "react";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const therapyReducer = useSelector((state) => state.TherapyReducer);
  const consentFormStatus = useSelector(
    (state) => state.IntakeFormReducer.consentFormStatus
  );
  const authStore = useSelector((state) => state.AuthReducer); //store data
  const { logOutAction } = bindActionCreators(ActionCreators, dispatch); //call action
  const loginStatus = authStore.loginStatus;
  const [current, setCurrent] = useState("mail");
  const [disableTherapyBtn, setdisableTherapyBtn] = useState(false)

  useEffect(() => {

    if (therapyReducer) {
      console.log('Usama', therapyReducer);

      if (
        !therapyReducer?.result?.patient_profile?.instructor &&
        !therapyReducer?.result?.appointment
      ) {
        // console.log('Usama disable therapy tab');
        setdisableTherapyBtn(true)
      }else{
        // console.log('Usama enable therapy tab');
        setdisableTherapyBtn(false)
      }

      // let { userProfileInfo } = therapyReducer?.result

      // if (Object.keys(patient_profile).length === 0) {
      //   console.log('Usama no patient profile');
      // } else {
      //   console.log('Usama has patient profile');
      // }
    }


  }, [therapyReducer])


  const onClick = (e) => {
    setCurrent(e.key);
  };

  const gotoHome = () => {
    navigate("/");
  };


  const authHandle = () => {
    if (loginStatus) {
      googleTagFire("Sign Out");
      setToken(null);
      signOut(auth)
        .then(() => {
          navigate("/");
          persistor.purge();
          logOutAction();
        })
        .catch(() => {
          showMessege("error", "Logout Unsuccessfull");
        });
    } else {
      navigate("/sign-in");
    }
  };

  const items = [
    {
      label: (
        <Link to="/therapy" className={styles.navItemText}>
          Therapy
        </Link>
      ),
      key: "therapy",
    },
    {
      label: (
        <Link
          to="https://go.onelink.me/5wa8/2a695a90"
          className={styles.navItemText}
          target="_blank"
        >
          Download The App
        </Link>
      ),
      key: "download",
    },

    {
      label: (
        <>
          <Link to="/settings" className={styles.navItemText}>
            Settings
          </Link>
          {consentFormStatus && (
            <span>
              <FaCircle
                color={"#FD2222"}
                style={{
                  height: 9,
                  width: 9,
                  marginBottom: 14,
                }}
              />
            </span>
          )}
        </>
      ),
      key: "settings",
    },

    {
      label: (
        <Button
          type="primary"
          size="large"
          className={styles.signInBtn}
          shape="round"
          onClick={authHandle}
        >
          {loginStatus ? "Sign Out" : "Sign In"}
        </Button>
      ),
      key: "sign_in_or_out",
    },
  ].filter((item) => {
    if (!loginStatus) {
      return item.key === "sign_in_or_out" || item.key === "download";
    } else {
      if (disableTherapyBtn) {
        if (item?.key !== 'therapy') {
          return item;
        }
      }else{
        return item;
      }

      // return item;

    }


  });

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#00b96b",
        },
      }}
    >
      <Row className={styles.header}>
        <Col lg={8} md={7} sm={9} xs={24} className={styles.logoCol}>
          <img
            src={headerLogo}
            className={styles.logo}
            alt="logo"
            onClick={gotoHome}
          />
        </Col>
        <Col lg={16} md={17} sm={15} xs={24} className={styles.menuCol}>
          <Menu
            style={{ display: "flex", justifyContent: window?.innerWidth < 500 ? 'center' : "flex-end" }}
            onClick={onClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={items}
          />
        </Col>
      </Row>
    </ConfigProvider>
  );
}

export default Header;
