import { REHYDRATE } from "redux-persist/lib/constants";
import { take, takeEvery, takeLatest } from "redux-saga/effects";
import { constant } from "../constant";
import {
  confirmBookingSagaAction,
  newSessionPreCleanUpSagaAction,
} from "./ConfirmBookingSagaAction";
import { getInstructorAvailablitySagaAction } from "./InstructorAvailablitySagaAction";
import { getPreAppointmentDataSagaAction } from "./PreAppointmentDataSageAction";
import { getQuestionnaireSagaAction } from "./QuestionnaireSagaAction";
import { getTherapySagaAction } from "./TherapySagaAction";
import { getConsentFormStatus } from "./IntakeFormSagaAction";
import { userLogOutAction } from "./UserAuthSagaAction";

// watchers
function* SagaActions() {
  yield take(REHYDRATE);
  yield takeEvery(constant.LOGOUT, userLogOutAction);
  yield takeLatest(
    constant.NEW_SESSION_PRE_CLEANUP,
    newSessionPreCleanUpSagaAction
  );

  // pre appointment data
  yield takeLatest(
    constant.GET_PRE_APPOINTMENT_DATA,
    getPreAppointmentDataSagaAction
  );
  // instructor availibility
  yield takeLatest(
    constant.GET_INSTRUCTOR_AVAILABLITY,
    getInstructorAvailablitySagaAction
  );

  yield takeLatest(constant.CONFIRM_BOOKING, confirmBookingSagaAction);

  // therapy index
  yield takeLatest(constant.GET_THERAPY_INDEX, getTherapySagaAction);

  // questionnaire
  yield takeLatest(constant.GET_QUESTIONNAIRE, getQuestionnaireSagaAction);

  //intakeform
  yield takeLatest(constant.GET_CONSENT_FORM_STATUS, getConsentFormStatus);
}
export default SagaActions;
